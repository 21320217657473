try {
    window.$ = window.jQuery = require('jquery');
    window.coreui = require('@coreui/coreui');
    window.Swal = require('sweetalert2');

    require('@popperjs/core');
    require('bootstrap');

    require('simplebar');

    require('datatables.net');
    require('datatables.net-bs4');

    require('howler');
    require('select2');

    const dt_spinner = `
        <span class="text-center">
            <span class="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
            <strong>Please wait...</strong>
        </span>`;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.extend($.fn.dataTable.defaults, {
        processing: false,
        responsive: true,
        searching: false,
        lengthChange: false,
        dom: '<\'row\'<\'col-12 mt-2\'<\'table-responsive\'t>>>' + '<\'row mt-2 align-items-center\'<\'col-12 col-md-7 d-flex justify-content-around justify-content-md-start my-1 my-md-0\'i><\'col-12 col-md-5 my-1 my-md-0\'p>>',
        language: {
            'processing': dt_spinner,
        },
        orderMulti: false,
        filtering: false,
        createdRow(row) {
            $(row).addClass('align-top');
        },
    });

    $.fn.select2.defaults.set('theme', 'bootstrap-5');

    window.swal_success = Swal.mixin({
        icon: 'success',
        title: 'Success!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        focusCancel: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary w-46', popup: 'border shadow', actions: 'w-90 justify-content-around mb-3',
        },
        willOpen: function () {
            $('html, body').animate({
                scrollTop: 0
            }, 50);
        },
        didOpen: function () {
            new Howl({
                src: [$('meta[name="sound-success"]').attr('content')],
            }).play();
        }
    });

    window.swal_error = Swal.mixin({
        icon: 'error',
        title: 'Error!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        focusCancel: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-secondary w-46', popup: 'border shadow', actions: 'w-90 justify-content-around mb-3',
        },
        willOpen: function () {
            $('html, body').animate({
                scrollTop: 0
            }, 50);
        },
        didOpen: function () {
            new Howl({
                src: [$('meta[name="sound-error"]').attr('content')],
            }).play();
        }
    });

    window.swal_warning = Swal.mixin({
        icon: 'warning',
        title: 'Warning!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        focusCancel: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-secondary w-46', popup: 'border shadow', actions: 'w-90 justify-content-around mb-3',
        },
        willOpen: function () {
            $('html, body').animate({
                scrollTop: 0
            }, 50);
        },
        didOpen: function () {
            new Howl({
                src: [$('meta[name="sound-error"]').attr('content')],
            }).play();
        }
    });

    window.swal_question = Swal.mixin({
        icon: 'question',
        title: 'Are you sure?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        focusCancel: true,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
            confirmButton: 'btn btn-primary w-46',
            cancelButton: 'btn btn-secondary w-46',
            popup: 'border shadow',
            actions: 'w-90 justify-content-around mb-3',
        },
        didOpen: function () {
            new Howl({
                src: [$('meta[name="sound-question"]').attr('content')],
            }).play();
        }
    });

    window.swal_loading = Swal.mixin({
        icon: 'info',
        title: 'Please wait...',
        text: '\n',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        focusCancel: true,
        showCancelButton: false,
        showConfirmButton: false
    });
} catch (e) {
    console.error(e);
}
